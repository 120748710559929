import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import "./Chart.css";
const PieChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");
    chartInstance.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: [
          "Public",
          "Liquidity Supply",
          "Team",
          "Partnerships & Advisors",
          "Incubator Grant",
          "Marketing & Staking Rewards",
          "Foundation Reserve ",
        ],

        datasets: [
          {
            label: "Dataset 2",
            data: [21, 7.5, 10, 5, 5, 30, 21.5],
            backgroundColor: [
              "#282d34",
              "#f3ba2f",
              "#ff4b19",
              "#21bf73",
              "#a52a2a",
              "#282d34",
              "#64708b",
              "#482d34",
            ],
            borderWidth: 0,
            hoverOffset: 10,
            // : 4,
          },
        ],
      },
      options: {
        cutout: "75%",
        devicePixelRatio: 4,
        layout: {
          //   padding: 20,
        },
        plugins: {
          tooltip: {
            position: "nearest",
            backgroundColor: "white",
            titleColor: "gray",
            borderColor: "#f7931a",
            borderWidth: "1px",
            bodyColor: "gray",
            titleFont: "light",
            callbacks: {
              label: function (data) {
                // 32.6%: 6,846,000 tokens
                console.log("data: ", data);
                let label =
                  `${data.parsed}%:` +
                  " " +
                  ((data.parsed / 100) * 21000000).toLocaleString() +
                  " " +
                  "tokens";
                return label;
              },
            },
          },
          legend: {
            labels: {},
          },
        },

        // maintainAspectRatio: false,
      },
      //   responsive: {
      //     responsive: false,
      //   },
    });
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);
  return (
    <div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default PieChart;
