import React from 'react';
import './Intro.css'
const Intro = () => {
    return (
      <div className="css-vurnku">
        <svg
          width={94}
          height={24}
          viewBox="0 0 94 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8604 18.3465C12.3271 18.3465 12.6071 18.7199 12.6071 19.0932C12.6071 19.5599 12.2337 19.8399 11.8604 19.8399C11.3937 19.8399 11.1137 19.4665 11.1137 19.0932C11.1137 18.7199 11.3937 18.3465 11.8604 18.3465ZM24.0871 13.5865C23.6204 13.5865 23.3404 13.2132 23.3404 12.8399C23.3404 12.4665 23.7137 12.0932 24.0871 12.0932C24.5537 12.0932 24.8337 12.4665 24.8337 12.8399C24.8337 13.2132 24.4604 13.5865 24.0871 13.5865ZM24.0871 10.4132C22.7804 10.4132 21.6604 11.5332 21.6604 12.8399C21.6604 13.1199 21.6604 13.3999 21.7537 13.5865L13.8204 17.7865C13.3537 17.1332 12.6071 16.7599 11.8604 16.7599C10.9271 16.7599 10.0871 17.3199 9.71372 18.0665L2.62039 14.3332C1.87373 13.9599 1.31373 12.6532 1.40706 11.5332C1.40706 10.9732 1.68706 10.5065 1.96706 10.3199C2.15373 10.2265 2.43373 10.2265 2.62039 10.3199H2.71373C4.58039 11.3465 10.8337 14.5199 11.0204 14.7065C11.3937 14.8932 11.6737 14.9865 12.3271 14.6132L25.1137 7.98654C25.3004 7.89321 25.4871 7.70654 25.4871 7.42654C25.4871 7.05321 25.1137 6.86654 25.1137 6.86654C24.3671 6.4932 23.2471 6.02654 22.2204 5.46654C19.8871 4.34654 17.2737 3.1332 16.0604 2.5732C15.0337 2.0132 14.2871 2.47987 14.1004 2.5732L13.8204 2.66654C8.59372 5.27987 1.59373 8.73321 1.22039 8.91987C0.473725 9.38654 0.100393 10.2265 0.00705946 11.2532C-0.0862739 12.9332 0.753726 14.7065 2.06039 15.3599L9.62039 19.2799C9.80706 20.4932 10.8337 21.3332 11.9537 21.3332C13.2604 21.3332 14.3804 20.3065 14.3804 18.9999L22.6871 14.5199C23.1537 14.8932 23.6204 14.9865 24.1804 14.9865C25.4871 14.9865 26.6071 13.8665 26.6071 12.5599C26.4204 11.4399 25.3937 10.4132 24.0871 10.4132Z"
            fill="#3883FF"
          />
          <path
            d="M42.287 16.7599C41.8204 17.2266 41.167 17.6933 40.327 17.9733C39.487 18.2533 38.5537 18.4399 37.527 18.4399C35.9404 18.4399 34.6337 17.9733 33.7004 16.9466C32.767 16.0133 32.207 14.6133 32.207 12.9333V11.9066C32.207 10.6933 32.3937 9.66661 32.8604 8.82661C33.327 7.89328 33.887 7.23994 34.6337 6.77328C35.3804 6.30661 36.3137 6.02661 37.3404 6.02661C38.8337 6.02661 40.047 6.39995 40.887 7.05328C41.727 7.70661 42.1937 8.73328 42.3804 10.1333H39.5804C39.487 9.47994 39.3004 9.01328 38.927 8.73328C38.5537 8.45328 38.087 8.26661 37.527 8.26661C36.7804 8.26661 36.2204 8.54661 35.847 9.19994C35.4737 9.85328 35.287 10.6933 35.1937 11.8133V12.5599C35.1937 13.7733 35.3804 14.7066 35.847 15.2666C36.2204 15.8266 36.8737 16.1999 37.807 16.1999C38.5537 16.1999 39.1137 16.0133 39.487 15.7333V13.8666H37.4337V11.9066H42.3804V16.7599H42.287Z"
            fill="#3883FF"
          />
          <path
            d="M43.9674 18.2534H46.7674V9.29339H43.9674V18.2534ZM43.874 6.96006C43.874 6.58673 44.0607 6.21339 44.3407 5.93339C44.6207 5.65339 44.994 5.56006 45.4607 5.56006C45.9274 5.56006 46.3007 5.65339 46.5807 5.93339C46.8607 6.21339 47.0474 6.49339 47.0474 6.96006C47.0474 7.33339 46.8607 7.70673 46.5807 7.98673C46.3007 8.26673 45.9274 8.36006 45.4607 8.36006C44.994 8.36006 44.6207 8.26673 44.3407 7.98673C43.9674 7.70673 43.874 7.42673 43.874 6.96006Z"
            fill="#3883FF"
          />
          <path
            d="M51.8072 7.05322V9.29322H53.3005V11.2532H51.8072V15.3599C51.8072 15.7332 51.9005 15.9199 51.9939 16.1066C52.0872 16.1999 52.3672 16.2932 52.7405 16.2932C53.0205 16.2932 53.2072 16.2932 53.3939 16.1999V18.1599C52.9272 18.3466 52.3672 18.4399 51.8072 18.4399C50.8739 18.4399 50.1272 18.2532 49.6605 17.7866C49.1939 17.3199 49.0072 16.6666 49.0072 15.7332V11.2532H47.8872V9.29322H49.0072V7.05322C49.0072 7.05322 51.8072 7.05322 51.8072 7.05322Z"
            fill="#3883FF"
          />
          <path
            d="M57.407 13.12V16.0134H59.2737C59.8337 16.0134 60.207 15.92 60.487 15.64C60.767 15.36 60.9537 15.08 60.9537 14.6134C60.9537 13.5867 60.487 13.12 59.4603 13.12H57.407ZM57.407 11.2534H58.9003C59.5537 11.2534 60.0203 11.16 60.3003 10.88C60.5803 10.6934 60.6737 10.32 60.6737 9.85338C60.6737 9.38671 60.487 9.01338 60.207 8.73338C59.927 8.54671 59.4603 8.36005 58.807 8.36005H57.407V11.2534ZM54.5137 18.2534V6.21338H58.9003C60.487 6.21338 61.607 6.49338 62.447 7.05338C63.287 7.61338 63.6603 8.45338 63.6603 9.57338C63.6603 10.2267 63.4737 10.6934 63.1937 11.16C62.9137 11.6267 62.447 11.9067 61.887 12.0934C62.5403 12.28 63.007 12.56 63.3803 13.0267C63.7537 13.4934 63.847 14.0534 63.847 14.7067C63.847 15.92 63.4737 16.76 62.727 17.4134C61.9803 17.9734 60.8603 18.3467 59.367 18.3467L54.5137 18.2534Z"
            fill="#3883FF"
          />
          <path
            d="M67.7673 13.8667C67.7673 14.7067 67.8606 15.2667 68.1406 15.7334C68.4206 16.1067 68.794 16.2934 69.2606 16.2934C70.2873 16.2934 70.754 15.5467 70.754 13.96V13.68C70.754 12.0934 70.194 11.2534 69.1673 11.2534C68.234 11.2534 67.7673 11.9067 67.674 13.3067L67.7673 13.8667ZM64.9673 13.68C64.9673 12.7467 65.154 12 65.5273 11.2534C65.9006 10.6 66.3673 10.04 67.0206 9.66669C67.674 9.29336 68.4206 9.10669 69.354 9.10669C70.6606 9.10669 71.7806 9.48002 72.5273 10.32C73.274 11.16 73.6473 12.28 73.6473 13.68V13.8667C73.6473 15.2667 73.2739 16.3867 72.4339 17.2267C71.6873 18.0667 70.5673 18.44 69.2606 18.44C67.954 18.44 66.9273 18.0667 66.1806 17.32C65.434 16.5734 64.9673 15.5467 64.9673 14.24V13.68Z"
            fill="#3883FF"
          />
          <path
            d="M77.3806 13.8667C77.3806 14.7067 77.4739 15.2667 77.7539 15.7334C78.0339 16.1067 78.4072 16.2934 78.8739 16.2934C79.9006 16.2934 80.3672 15.5467 80.3672 13.96V13.68C80.3672 12.0934 79.8072 11.2534 78.7806 11.2534C77.8472 11.2534 77.3806 11.9067 77.2872 13.3067L77.3806 13.8667ZM74.5806 13.68C74.5806 12.7467 74.7672 12 75.1406 11.2534C75.5139 10.6 75.9806 10.04 76.6339 9.66669C77.2872 9.29336 78.0339 9.10669 78.9672 9.10669C80.2739 9.10669 81.3939 9.48002 82.1406 10.32C82.8872 11.16 83.2606 12.28 83.2606 13.68V13.8667C83.2606 15.2667 82.8872 16.3867 82.0472 17.2267C81.3006 18.0667 80.1806 18.44 78.8739 18.44C77.5672 18.44 76.5406 18.0667 75.7939 17.32C75.0472 16.5734 74.5806 15.5467 74.5806 14.24V13.68Z"
            fill="#3883FF"
          />
          <path
            d="M88.1141 14.9867L87.2741 15.7334V18.2534H84.4741V5.56006H87.2741V12.2801L87.5541 11.9067L89.7008 9.29339H93.0608L89.8875 13.0267L93.2475 18.2534H90.0741L88.1141 14.9867Z"
            fill="#3883FF"
          />
        </svg>
        <a data-bn-type="link" href="https://satopad-finance.gitbook.io/satopad-finance/satopad-ecosystem-v1/governance" target="_blank" className="css-9wj43q">
          <div className="css-1xjoiqz">
            <div data-bn-type="text" className="css-1vze2jw">
              Intro to SatoPAD DAO Governance
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="governance-landing-page-explore-link css-1l1o9sq"
            >
              <path
                d="M11 5.00008C10.4477 5.00009 9.99999 5.4478 9.99999 6.00008C9.99999 6.55237 10.4477 7.00009 11 7.00008L15.5861 7L7.21385 15.2929C6.82015 15.6829 6.81864 16.3186 7.21047 16.7105C7.59968 17.0997 8.23024 17.1012 8.6213 16.7138L17.0004 8.41411L17.0011 13.0001C17.0012 13.5523 17.4489 14 18.0011 14C18.5534 14 19.0012 13.5522 19.0011 12.9999L19.0005 5.99992C19.0004 5.44767 18.5527 5 18.0005 5.00001L11 5.00008Z"
                fill="#72768F"
              />
            </svg>
          </div>
        </a>
        <div data-bn-type="text" className="css-oqo35a">
          Read about SatoPAD and how the governance process works.
        </div>
      </div>
    );
}

export default Intro;
