import React, { useState } from "react";
import "./Details.css";
import Circle from "../../../assets/img/download (1).png";
import HeartIcon from "../../../assets/img/Shield.svg";
import NetworkIco from "../../../assets/img/btc-icon.png";
import PieChart from "../../PieChart/Chart";
import Txt from "../../../assets/img/txt.webp";
import EcoFeatures from "../../../assets/img/eco-features.png";
const Details = ({yourTokens, IDOStartTime}) => {

  const [Copied, setCopied] = useState("00d5…63i0");
  let copyit = () => {
    navigator.clipboard.writeText(
      "00d535234ad353b08e52eda51510046996b9c5e0e174fc64e979768f137c4c63i0"
    );
    setCopied("Address copied");
    setTimeout(() => {
      setCopied("00d5…63i0");
    }, 1500);
  };
  return (
    <div className="wallet-pools">
      <div data-v-bab650ea className="content3 container">
        <div className="clo1">
          <div className="container muiao MuiBox-root css-1q7njkh  ">
            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3 css-sag665">
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-6 sc-dNckAz eLNKzK css-iol86l">
                <div className="sc-iNIeMn kQGbCJ">
                  <div className="sc-fTyFcS dYczhi">
                    <p className=" sc-knefzF  cVoruI font1">Pool Information</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Target Raise</p>
                    <p className="sc-hsUFQk dSlGvg">11.2 BTC</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Price per Token</p>
                    <p className="sc-hsUFQk dSlGvg">1 SAPI = 0.00000250 BTC</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Accepted Currency</p>
                    <p className="sc-hsUFQk dSlGvg">BTC</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Allocation Range</p>
                    <p className="sc-hsUFQk dSlGvg">0.001 BTC - 0.5 BTC</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Vesting Type</p>
                    <p className="sc-hsUFQk dSlGvg">N/A</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi ">
                    <p className="sc-hsUFQk dSlGvg">IDO Start Time</p>
                    <p className="sc-hsUFQk dSlGvg">{IDOStartTime ? (IDOStartTime) : ("TBA")}</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-knefzF cVoruI">Token Info</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Token Name</p>
                    <p className="sc-hsUFQk dSlGvg">
                      <a
                        href="https://ordiscan.com/brc20/sapi"
                        target="_blank"
                        className="token-info"
                      >
                        SatoPad ($SAPI)
                      </a>
                    </p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Category</p>
                    <p className="sc-hsUFQk dSlGvg">
                      <div
                        className="f-detail-tags"
                        style={{ "margin-bottom": "0" }}
                      >
                        <span>DeFi</span>
                        <span>Launchpad</span>
                      </div>
                    </p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Total Supply</p>
                    <p className="sc-hsUFQk dSlGvg">21,000,000 SAPI</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Tokens for Sale</p>
                    <p className="sc-hsUFQk dSlGvg">4,410,000 SAPI</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Initial Market Cap</p>
                    <p className="sc-hsUFQk dSlGvg">$903,536</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi">
                    <p className="sc-hsUFQk dSlGvg">Token Listing</p>
                    <p className="sc-hsUFQk dSlGvg">TBA</p>
                  </div>
                  <div className="sc-fTyFcS dYczhi  no-border">
                    <p className="sc-hsUFQk dSlGvg">Inscription ID</p>
                    <div className="MuiBox-root css-1pt0vlt">
                      <img src={NetworkIco} width={24} alt="network" />
                      <p className="sc-hsUFQk dSlGvg">{Copied}</p>

                      <button
                        onClick={copyit}
                        title
                        type="button"
                        className="sc-keuYuY jDbtIU flex"
                        data-clipboard-text="0x5a093a9c4f440c6b105F0AF7f7C4f1fBE45567f9"
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="sc-jwWbkn kUbNon"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.58398 4.63965V13.473H15.4173V4.63965H6.58398ZM6.16732 3.38965C5.70708 3.38965 5.33398 3.76274 5.33398 4.22298V13.8896C5.33398 14.3499 5.70708 14.723 6.16732 14.723H15.834C16.2942 14.723 16.6673 14.3499 16.6673 13.8896V4.22298C16.6673 3.76274 16.2942 3.38965 15.834 3.38965H6.16732Z"
                            fillOpacity="0.7"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.33398 5.59668C3.67916 5.59668 3.95898 5.8765 3.95898 6.22168V15.9856H13.7229C14.0681 15.9856 14.3479 16.2654 14.3479 16.6106C14.3479 16.9557 14.0681 17.2356 13.7229 17.2356H3.33398C2.98881 17.2356 2.70898 16.9557 2.70898 16.6106V6.22168C2.70898 5.8765 2.98881 5.59668 3.33398 5.59668Z"
                            fillOpacity="0.7"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div data-v-bab650ea className="car1">
            <div className="launchpad_detail_item__ybyD_">
              <div className="launchpad_detail_title__cJdYk">
                Introduction to SatoPAD Finance
              </div>
              <p>
              SatoPAD Finance is redefining the Bitcoin DeFi landscape, positioning itself as a comprehensive platform that transcends traditional launchpad services. We are committed to pioneering the integration of various blockchain ecosystems, including BRC-20, Ethereum (and EVM-compatible chains), and Stacks, to create a diverse and dynamic DeFi experience. Our platform is not merely a gateway; it's an all-encompassing bridge to the future of decentralized finance on Bitcoin.
                <br /> <br />
                Our vision is to spearhead innovation within the Bitcoin DeFi space. By blending these diverse blockchain technologies, we aim to unlock unprecedented possibilities and amplify the financial potential of Bitcoin's expansive network. Our approach is to provide a holistic solution, making decentralized finance more accessible and impactful. SatoPAD is dedicated to fostering growth, adoption, and innovation in the Bitcoin ecosystem, paving the way for a new era of financial inclusivity and technological advancement in the world of Bitcoin DeFi.
              </p>

              <div className="launchpad_detail_title__cJdYk">
                SatoPAD's Unique Value Proposition
              </div>
              <p>
                At SatoPAD Finance, we seamlessly blend cutting-edge
                decentralized finance (DeFi) services with a steadfast
                commitment to user security and convenience. Our platform is
                meticulously crafted with a focus on the user experience,
                boasting an intuitive interface and streamlined processes for
                ease of use. We offer a comprehensive suite of DeFi tools and
                services, encompassing a variety of launch models, flexible
                staking opportunities, and pioneering auction algorithms.
                Prioritizing the safety of our users' investments, SatoPAD
                incorporates robust security measures, establishing itself as a
                secure and adaptable platform for anyone involved in the Bitcoin
                DeFi ecosystem.
                <br /> <br />
                <div className="img-bg">
                  {" "}
                  <img src={EcoFeatures} className="txt-img" alt="" />{" "}
                  <h1 className="title">
                    <a
                      className="project-desc-link"
                      href="https://satopad-finance.gitbook.io/satopad-finance/or-satopad-finance-blueprint/defi-solutions-by-satopad-finance"
                      target="_blank"
                    >
                      SatoPAD Ecosystem V1
                    </a>
                  </h1>
                </div>
                <br />
                Concluding, SatoPAD Finance stands as a testament to innovation
                in the Bitcoin DeFi space, blending advanced functionality with
                a strong commitment to community-driven governance. We welcome
                you to experience the unique value and comprehensive features
                that define our platform, and join us in shaping the future of
                decentralized finance.
              </p>

              <div className="launchpad_detail_title__cJdYk">
                Unveiling the Utility of $SAPI
              </div>
              <p>
                $SAPI is the cornerstone token of SatoPAD Finance, pivotal in
                driving innovation within our DeFi platform on the Bitcoin
                network. It's designed not just as a digital asset but as a key
                enabler of platform functionalities, aligning with our vision of
                an inclusive and efficient DeFi ecosystem.
                <br /> <br />
                <div className="img-bg">
                  {" "}
                  <img src={Txt} className="txt-img" alt="" />
                  <h1 className="title">
                    <a
                      className="project-desc-link"
                      href="https://satopad-finance.gitbook.io/satopad-finance/token-overview/what-is-usdsapi"
                      target="_blank"
                    >
                      The Strength of $SAPI
                    </a>
                  </h1>
                </div>
                <br />
                $SAPI is more than a token; it's a symbol of commitment to
                SatoPAD's mission to revolutionize the Bitcoin DeFi space. As
                the platform evolves, the utility and significance of $SAPI will
                continue to expand, playing a vital role in the growth of the
                Bitcoin DeFi ecosystem.
              </p>

              <div className="launchpad_detail_title__cJdYk">
                Bridging Networks and Empowering Innovators
              </div>
              <p>
                SatoPAD introduces pioneering functionalities like our bridge
                feature, enhancing liquidity and fostering the growth of the
                BRC-20 ecosystem. This feature allows seamless asset transfers
                across blockchain networks, including Ethereum and Binance Smart
                Chain, revolutionizing asset mobility. We are committed to
                supporting innovators and visionaries of the Bitcoin network,
                providing them with tailored launch experiences and embracing
                the principles of decentralization and community participation.
                Join us on our journey to unlock the full potential of
                Bitcoin-based DeFi, as we shape the future of decentralized
                finance on the world's leading cryptocurrency network.
              </p>
            </div>
          </div>
        </div>
        <div className="r" data-v-bab650ea>
          <div data-v-bab650ea className="car4" style={{ opacity: "0.5" }}>
            <div data-v-bab650ea className="font1">
              Token Claim
            </div>{" "}
            <form data-v-bab650ea className="el-form form">
              <div data-v-bab650ea className="row">
                <div data-v-bab650ea className="el-form-item form-item p-b-1">
                  <label htmlFor="name" className="el-form-item__label">
                    Your Tokens
                  </label>
                  <div className="el-form-item__content">
                    <div data-v-bab650ea className="el-input">
                      <input
                        type="text"
                        readOnly="readonly"
                        placeholder="0"
                        autoComplete="off"
                        className="el-input__inner"
                        value={yourTokens}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div data-v-bab650ea className="el-form-item form-item p-b-1">
                  <label className="el-form-item__label">Total Claimed</label>
                  <div className="el-form-item__content">
                    <div data-v-bab650ea className="el-input">
                      <input
                        type="text"
                        readOnly="readonly"
                        placeholder="0"
                        autoComplete="off"
                        className="el-input__inner"
                      />
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div data-v-bab650ea className="row">
                <div data-v-bab650ea className="el-form-item form-item p-b-1">
                  <label htmlFor="name" className="el-form-item__label">
                    Claimable Now
                  </label>
                  <div className="el-form-item__content">
                    <div data-v-bab650ea className="el-input">
                      <input
                        type="text"
                        readOnly="readonly"
                        autoComplete="off"
                        placeholder="0"
                        className="el-input__inner"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div data-v-bab650ea className="el-form-item form-item p-b-1">
                  <label htmlFor="name" className="el-form-item__label">
                    Next Unlock Date
                  </label>
                  <div className="el-form-item__content">
                    <div data-v-bab650ea className="next-unlock-date">
                      <div data-v-bab650ea />{" "}
                      <img
                        data-v-bab650ea
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgB7ZExCoAwDEV/emOd7GSd6iSeuBWRiFmK0UwlDzIkkAc/ARxmjGs96+2cCTCGns0QcyLQDAUFddlzTNwHadfJLoHcMY9sjrhh63sttjzdHvPILnThb2GlBCXlw05nHBY2GeRN5napAAAAAElFTkSuQmCC"
                      />
                      &nbsp; TBA
                    </div>
                  </div>
                </div>
              </div>{" "}
            </form>{" "}
            <div data-v-bab650ea className="date">
              <div data-v-bab650ea className="i">
                <div data-v-bab650ea>0</div> <div data-v-bab650ea>days</div>
              </div>{" "}
              <div data-v-bab650ea className="i">
                <div data-v-bab650ea>0</div> <div data-v-bab650ea>hours</div>
              </div>{" "}
              <div data-v-bab650ea className="i">
                <div data-v-bab650ea>0</div> <div data-v-bab650ea>minutes</div>
              </div>{" "}
              <div data-v-bab650ea className="i">
                <div data-v-bab650ea>0</div> <div data-v-bab650ea>seconds</div>
              </div>
            </div>{" "}
            <div
              data-v-bab650ea
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "38px",
              }}
            >
              <div
                data-v-8ad3c7da
                data-v-bab650ea
                className="button btn disabled"
              >
                <i class="fa-solid fa-hand-holding-dollar"></i>&nbsp;
                <span data-v-8ad3c7da>Claim</span>
              </div>{" "}
              <div
                data-v-8ad3c7da
                data-v-bab650ea
                className="button btn disabled"
                style={{ right: "1px" }}
              >
                <i class="fa-solid fa-coins"></i>&nbsp;
                <span data-v-8ad3c7da>Refund</span>
              </div>
            </div>{" "}
          </div>
          <div data-v-bab650ea className="car4 mt-as">
            <div className="launchpad_detail_card__Pnp8q undefined">
              <div data-v-bab650ea className="font1">
                Tokenomics
              </div>{" "}
              <div>
                {/* <img src={Circle} alt="" />
                 */}
                <PieChart />
              </div>
            </div>
          </div>
          <div className=" mt-as inhed bg-[#F7F7F7] rounded-[1.1rem] z-50 relative border-transparent pool-bg-color">
            <div
              className="flex gap-5 items-center"
              currprojectphase={3}
              project="[object Object]"
            >
              <div className>
                <div className="rounded-lg    ">
                  <img src={HeartIcon} height="30px" alt="" />
                </div>
              </div>
              <div>
                This launch is protected by SatoPAD Shield.{" "}
                <a
                  href="https://satopad-finance.gitbook.io/satopad-finance/satopad-ecosystem-v1/launchpad/satopad-shield"
                  className="underline font-inter-bold pool-whitelist-link"
                  target="_blank"
                >
                  Click here
                </a>{" "}
                to learn more.
              </div>
            </div>
          </div>
          <div data-v-bab650ea className="car4 mt-as">
            <div className="launchpad_detail_card__Pnp8q undefined">
              <div data-v-bab650ea className="font1" style={{ color: "red" }}>
                IDO Disclaimer
              </div>{" "}
              <p class="launchpad_detail_red__6hPWI ">
                * By choosing to participate in this sale, you affirm and
                confirm that you are not located in, nor a citizen or resident
                of the United States of America, People's Republic of China,
                Bermuda, Burundi, Central African Republic, Cuba, Democratic
                Republic of Congo, Eritrea, Guinea-Bissau, Iran, Libya, Mali,
                North Korea, Palestine, Republic of Seychelles, Somalia, South
                Sudan, Sudan, Syria, Western Sahara, Yemen, Crimea and
                Sevastopol, or any other state, country, or jurisdiction where
                participation in this launch would contravene applicable laws
                and regulations. It is your responsibility to ensure that your
                participation complies with local laws and regulations, and
                SatoPAD Finance cannot be held responsible for any legal
                violations incurred by your participation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
