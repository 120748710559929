import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Span = ({ text, highlight }) => (
  <>
    <div className="toast-status">{text}</div>
    {highlight ? <div className="toast-highlight">{highlight}</div> : ""}
  </>
);

// status = info | success | warning | error | default

const triggerNotification = (
  wallet,
  text,
  status = "default",
  highlight = false
) => {
  if (status === "default") {
    toast(<Span text={text} highlight={highlight} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } else if (status === "info") {
    toast.info(<Span text={text} highlight={highlight} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } else if (status === "success") {
    toast.success(<Span text={text} highlight={highlight} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } else if (status === "warning") {
    toast.warning(<Span text={text} highlight={highlight} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  } else if (status === "error") {
    console.log(text);

    toast.error(<Span text={text} highlight={highlight} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
};

export default triggerNotification;
