import React from "react";
import { Spinner } from "react-bootstrap";

const CustomButton = ({
  primary,
  transparent,
  style,
  className,
  label,
  onClick,
  icon,
  isDisabled,
  noGlow,
  isLoading,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      className={`btn ${
        primary ? "btn-primary" : transparent ? "btn-transparent" : ""
      } ${className ? className : ""} ${noGlow ? "no-glow" : ""} ${
        isDisabled ? "ineffective" : ""
      }`}
      disabled={isDisabled}
    >
      {icon}&nbsp; {label}
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ marginLeft: "10px" }}
        />
      )}      
    </button>
  );
};

export default CustomButton;