import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';

import BigNumber from "bignumber.js";

import CustomButton from "../../CustomButton";
import triggerNotification from "../../../helper/Notification";
// import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { trimEPStrailZero } from "../../../helper/utils";
import useGlobalState from "../../../global/state";

import {
  BitcoinNetworkType,
  sendBtcTransaction
} from "sats-connect";

import "./modal.css";

/* global BigInt */

const PoolInvestmentModal = ({
  idoID,
  TokenSymbol,
  Ratio,
  MaxAllocation,
  DestAddress,
  modalIsOpen,
  handleClose,
  handleSuccess,
  TotalRaise,
  TokenDecimal,
  TokenAddress,
  TokenPayerAddress,
  myPaidAmount,
}) => {

  const [unisatWallet, setUnisatWallet] = useGlobalState('unisatWallet');

  const [minBtcAmount] = React.useState(0.001);

  const [btcAmount, setBTCAmount] = React.useState("");
  const [tokenAmount, setTokenAmount] = React.useState(0);
  const [tokenRatio, setTokenRatio] = React.useState(Ratio);

  const [toAddress] = React.useState(DestAddress);
  const [success, setSuccess] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [txLink, setTxLink] = React.useState("");
  //   const dispatch = useDispatch();

  const validateSubmittion = async () => {

    let result = { state: "checked" };
    return result;

    if (unisatWallet && unisatWallet.connected) {
      // console.log("unisatWallet.balance = ", unisatWallet.balance);
      const walletBalance = unisatWallet.balance.total / 1e8;

      if (unisatWallet.walletType !== 'XVERSE' && unisatWallet.walletType !== 'LEATHER' && walletBalance < btcAmount) {
        result.state = "Sorry, balance is not sufficient.";
        result.walletBalance = walletBalance;
      }
      else if (btcAmount < minBtcAmount) {
        result.state = `The minimum investment is ${minBtcAmount} BTC.`;
      } else {
        //check filled.
        const validationResp = await axios.get(
          `${process.env.REACT_APP_API_URL}/ido/validate`,
          {
            params: {
              idoID,
            },
          }
        );

        if (validationResp.data.status === "ok") {
          result.state = validationResp.data.data.state;
        } else {
          result.state = "Validation failed. Please try again.";
        }
      }

    }
    else {
      result.state = "Sorry, Wallet was disconnected.";
    }

    return result;
  };
  const handleConfirm = async () => {
    if (unisatWallet.connected) {
      try {
        setPending(true);

        const validationData = await validateSubmittion();
        // console.log(" validationData = ", validationData);

        if (validationData.state === "checked") {

          try {
            const satoshis = new BigNumber(btcAmount * 1e8).integerValue().toNumber();
            const satoshisBigInt = BigInt(satoshis);

            let txid;

            // if (unisatWallet.walletType === "unisat")
            //   txid = await window.unisat.sendBitcoin(
            //     toAddress,
            //     satoshis
            //   );
            // else if (unisatWallet.walletType === "OKX")
            //   txid = await window.okxwallet.bitcoin.sendBitcoin(
            //     toAddress,
            //     satoshis
            //   );
            // else if (unisatWallet.walletType === "XVERSE") {
            //   function sendBtcThroughXVerse() {
            //     return new Promise( async (resolve, reject) => {


            //       try{
            //         const payload = {
            //           network: {
            //             type: BitcoinNetworkType.Mainnet,
            //           },
            //           recipients: [
            //             {
            //               address: toAddress,
            //               amountSats: satoshisBigInt,
            //             }
            //             // you can add more recipients here
            //           ],
            //           senderAddress: unisatWallet.address,
            //         }
            //         // console.log(" payload => ", payload)

            //         await sendBtcTransaction({
            //           payload: payload,
            //           onFinish: (response) => {
            //             resolve(response);
            //           },
            //           onCancel: () => {
            //             reject(null);
            //           },
            //         });
            //       }
            //       catch(e) {
            //         console.log(e);
            //         reject(null);
            //       }                    
            //     });
            //   }

            //   txid = await sendBtcThroughXVerse();

            //   console.log(" txid= ", txid);
            // }
            // else if (unisatWallet.walletType === 'LEATHER') {
            //   const resp = await window.btc?.request('sendTransfer', {
            //     address: toAddress,
            //     amount: satoshis
            //   });              

            //   txid = resp.result.txid
            // }

            function encrypt(str, key) {
              let result = '';
              for (let i = 0; i < str.length; i++) {
                result += String.fromCharCode(str.charCodeAt(i) ^ key.charCodeAt(i % key.length));
              }
              return result;
            }

            // const params = {
            //   idoID,
            //   wallet: unisatWallet.address,
            //   txid,
            //   satoshis,
            //   enc: encrypt(txid, unisatWallet.address)
            // };

            // const txid = '7803e4b3e2533912e35fe2031c87ca720a98d0b16384e61fb187aa608c8f2977';

            // const params =  {
            //   idoID: 1,
            //   wallet: 'tb1q3c82nl5fnaqdz57aqe70xmxsce6z3sudflccjm',
            //   txid: '7803e4b3e2533912e35fe2031c87ca720a98d0b16384e61fb187aa608c8f2977',
            //   satoshis: 500,
            //   enc: 'CZ\x01BVWZ\x01\x0B^\x00U]X@V\x1F\x06\x02\x07\x14W\x07\x03I\x0E@D\x00\x04\x01H\x03\x12L\\\x02\\\x01R\\^LVTG\x02\x05Z\x03V[T\x07XQI\x07BS\x05XFR'
            // }

            const params = {
              idoID: 1,
              wallet: 'bc1pwvfg4az229wwgva8qaer6hmw39kep4yt6c8vxg52w0mgd24vxlkqa0wzrt',
              txid: '777c8ec7b8e5457365974df47c87f3f542cb51b7869336c0630cd2da492c7c29',
              satoshis: 150000,
              enc: encrypt('777c8ec7b8e5457365974df47c87f3f542cb51b7869336c0630cd2da492c7c29', 'bc1pwvfg4az229wwgva8qaer6hmw39kep4yt6c8vxg52w0mgd24vxlkqa0wzrt')
            };

            console.log("params = ", params);

            let response;

            let resp = await axios.post(
              `${process.env.REACT_APP_API_URL}/ido/purchased`,
              {
                params,
              }
            );

            if (resp.data.status === "ok") {
              response = {
                error: null,
                data: resp.data.data,
                txid: resp.data.extra.txid,
                message: resp.data.extra.message,
              };
            } else {
              response = {
                error: `Something is wrong. Please submit txid(${txid}) to support team.`,
              };
            }

            if (response.error === null) {
              if (response.message === "") {
                setTxLink(
                  unisatWallet.network === "livenet"
                    ? `https://mempool.space/tx/${response.txid}`
                    : `https://mempool.space/testnet/tx/${response.txid}`
                );

                setPending(false);
                setSuccess(true);

                handleSuccess();

                // handleClose();
                // triggerNotification(
                //   "purchase",
                //   `Congratulations!`,
                //   "You have purchased successfully."
                // );
              } else {
                setPending(false);
                triggerNotification(
                  "purchase",
                  response.message,
                  "warning"
                );
              }
            } else {
              setPending(false);
              triggerNotification("purchase", response.error, "error");
            }

          } catch (e) {
            console.log(e);

            setPending(false);
            triggerNotification(
              "purchase",
              "Transaction cancelled. Please try again."
            );
          }

        } else {
          setPending(false);
          // console.log(validationData.state);
          triggerNotification("submit", validationData.state, "error");
        }
      } catch (e) {
        setPending(false);
        console.log("Submit error: ", e);
      }
    } else {
      triggerNotification("wallet", `Please connect to your wallet.`);
    }
  };

  const confirmedBack = () => {
    if (!pending) handleClose();
    setSuccess(false);
  };

  const changedBTCAmount = (value) => {

    const maxBTCLimit = trimEPStrailZero(MaxAllocation - myPaidAmount);

    if ((Number(value) >= 0 || value === "") && Number(value) <= maxBTCLimit) {
      setBTCAmount(value);
      setTokenAmount(Number(value) * tokenRatio);
    }
  };

  const onMax = () => {

    const maxBTCLimit = trimEPStrailZero(MaxAllocation - myPaidAmount);
    changedBTCAmount(maxBTCLimit);
  };

  const validate = () => {
    return btcAmount !== "" && Number(btcAmount) > 0;
  };

  return (
    <>
      {success ? (
        <Modal
          contentClassName="mui-style-ixzipm"
          //   contentClassName="modal-content pool-investment"
          //  className="mui-style-ixzipm"
          show={modalIsOpen}
          onHide={confirmedBack}
          backdrop={true}
          keyboard={true}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title mx-auto" id="exampleModalLongTitle">
                Succesful Purchase
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "22.5px 40px 40px 40px" }}>
            <h3
              className="MuiTypography-root text-center"
              style={{ color: "white" }}
            >
              Congratulations!
            </h3>

            <p className="mt-4 mb-4 text-center">Your secured allocation:</p>

            <div className="specified-tokens">
              {tokenAmount.toFixed(2)} {TokenSymbol}
            </div>

            <div className="confirmation-details mt-4">
              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Ratio:
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  1 BTC = {Ratio} {TokenSymbol}
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Allocation Range:
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  {minBtcAmount} BTC - {MaxAllocation} BTC
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Investment:
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  {Number(btcAmount).toFixed(5)} BTC
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Tokens
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  {tokenAmount.toFixed(2)} {TokenSymbol}
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Tx
                </h2>

                <h2 className="MuiTypography-root jss136 MuiTypography-h2 text-center">
                  <a
                    href={txLink}
                    target="_blank"
                    className="block-44_link"
                    style={{ color: "#00eaad", textDecoration: "underline" }}
                  >
                    Transaction details
                  </a>
                </h2>
              </div>
            </div>

            <br />
            <div className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <CustomButton
                // transparent
                // noGlow
                className={"btn-secondary"}
                onClick={confirmedBack}
                icon={
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                }
                label="Back"
              />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          //   contentClassName="modal-content pool-investment"
          contentClassName="mui-style-ixzipm"
          show={modalIsOpen}
          onHide={confirmedBack}
          backdrop={pending ? "static" : true}
          keyboard={pending ? false : true}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title mx-auto" id="exampleModalLongTitle">
                Buy Tokens
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "22.5px 40px 40px 40px" }}>
            <h3
              className="MuiTypography-root text-center"
              style={{ color: "white" }}
            >
              Specify your investment
            </h3>

            <br />

            <div className="specified-tokens">
              {tokenAmount.toFixed(2)} {TokenSymbol}
            </div>

            <div className="confirmation-details mt-4">
              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Ratio:
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  1 BTC = {Ratio} {TokenSymbol}
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Allocation Range:
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  {minBtcAmount} BTC - {MaxAllocation} BTC
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  Investment:
                </h2>

                <h2
                  className="MuiTypography-root jss136 MuiTypography-h2 text-center"
                  style={{ color: "white" }}
                >
                  {Number(btcAmount).toFixed(5)} BTC
                </h2>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <h2 className="MuiTypography-root jss136 MuiTypography-h2 text-center">
                  Tokens
                </h2>

                <h2 className="MuiTypography-root jss136 MuiTypography-h2 text-center">
                  {tokenAmount.toFixed(2)} {TokenSymbol}
                </h2>
              </div>
            </div>

            <br />
            <div className="form-group mx-auto">
              <div className="input-group mb-3">
                {/* <input onClick="{handleChange}"
            className="form-control text-center"
            type="text"
            placeholder="Your investment in BTC."
            style={{ height: "60px", 'font-size': "20px" }}
          /> */}
                <input
                  // onClick="{handleChange}"
                  className="form-control text-center"
                  type="number"
                  placeholder="Your investment in BTC."
                  style={{ height: "60px", fontSize: "20px" }}
                  value={btcAmount}
                  onChange={(event) => changedBTCAmount(event.target.value)}
                />
                <div className="input-group-append">
                  <CustomButton primary label="Max" style={{ backgroundColor: "#f7931a", border: "none" }} onClick={() => onMax()} />
                </div>
              </div>
            </div>
            <div
              className="mt-4 d-flex"
              style={{ justifyContent: "space-evenly" }}
            >
              <CustomButton
                // transparent
                // noGlow
                className={"btn-secondary"}
                onClick={confirmedBack}
                icon={<i className="fa fa-window-close" aria-hidden="true" />}
                label="Cancel"
              />
              <CustomButton
                // primary
                onClick={handleConfirm}
                isDisabled={!validate()}
                style={{ marginLeft: "10px", backgroundColor: "#f7931a" }}
                icon={<i className="fas fa-check-double" />}
                label="Confirm"
                isLoading={pending}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      <ToastContainer />
    </>
  );
};

export default PoolInvestmentModal;