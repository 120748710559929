import WalletIcon from "../../assets/img/Connect-Wallet.svg";
import UniSatWallet from "../../assets/img/unisat-wallet.svg";
import LeatherWallet from "../../assets/img/leather-wallet.svg";
import ico2 from "../../assets/img/2.webp";
import ico3 from "../../assets/img/3.webp";

import useGlobalState from "../../global/state";
import triggerNotification from "../../helper/Notification";

import React, { useEffect, useRef, useState } from "react";

import { Capability } from "sats-connect";
import {
    AddressPurpose,
    BitcoinNetworkType,
    getAddress,
    getCapabilities,
} from "sats-connect";

const ConnectWalletDialog = ({ modalPop, closePop }) => {
    const [unisatWallet, setUnisatWallet] = useGlobalState('unisatWallet');
    // const [walletConnectingStatus, setWalletConnectingStatus] = useGlobalState('walletConnectingStatus');

    const [unisatInstalled, setUnisatInstalled] = useState(false);
    const [unisatInfo, setUnisatInfo] = useState({
        walletType: "", //unisat | OKX
        connected: false,
        accounts: [],
        publicKey: "",
        address: "",
        balance: {
            confirmed: 0,
            unconfirmed: 0,
            total: 0,
        },
        network: "livenet"
    });

    // XVerse
    const [xVerseNetwork] = useState(BitcoinNetworkType.Mainnet)

    const [capabilityState, setCapabilityState] = useState("loading");
    const [capabilities, setCapabilities] = useState(null);

    // const [accountChanged, setAccountChanged] = useState(false);

    const updateUnisatInfo = (key, value) => {
        setUnisatInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    const getBasicInfo = async () => {
        const unisat = (window).unisat;

        const [address] = await unisat.getAccounts();
        const publicKey = await unisat.getPublicKey();
        const balance = await unisat.getBalance();
        const network = await unisat.getNetwork();

        setUnisatInfo(prevState => ({
            ...prevState,
            connected: true,
            address: address,
            publicKey: publicKey,
            balance: balance,
            network: network,
            walletType: "unisat"
        }));
    };

    const selfRef = useRef({
        accounts: [],
    });
    const self = selfRef.current;
    const handleAccountsChanged = (_accounts) => {

        console.log(" = handleAccountsChanged = ");
        console.log(" unisatInfo 1 = ", unisatInfo);

        // setAccountChanged(true);

        // console.log("_accounts = ", _accounts, ", self.accounts = ", self.accounts);
        // console.log("unisatWallet = ", unisatWallet);

        // if (self.accounts.length > 0 && self.accounts[0] === _accounts[0]) {
        //     // prevent from triggering twice
        //     return;
        // }
        if (unisatWallet?.address === _accounts[0]) {
            // prevent from triggering twice
            return;
        }

        self.accounts = _accounts;
        if (_accounts.length > 0) {
            // updateUnisatInfo("accounts", _accounts);
            // updateUnisatInfo("connected", true);

            // updateUnisatInfo("address", _accounts[0]);

            getBasicInfo();
        } else {
            updateUnisatInfo("connected", false);
        }
    };

    const handleNetworkChanged = (network) => {
        getBasicInfo();
    };

    async function checkUnisatInstalled() {

        let unisat = (window).unisat;

        for (let i = 1; i < 10 && !unisat; i += 1) {
            await new Promise((resolve) => setTimeout(resolve, 100 * i));
            unisat = (window).unisat;
        }

        if (unisat) {
            setUnisatInstalled(true);
        } else if (!unisat)
            return;
    }


    async function checkUnisat() {

        // console.log(" = checkUnisat = ");

        let unisat = (window).unisat;

        for (let i = 1; i < 10 && !unisat; i += 1) {
            await new Promise((resolve) => setTimeout(resolve, 100 * i));
            unisat = (window).unisat;
        }

        unisat.getAccounts().then((accounts) => {
            handleAccountsChanged(accounts);
        });

        unisat.on("accountsChanged", handleAccountsChanged);
        unisat.on("networkChanged", handleNetworkChanged);

        return () => {
            unisat.removeListener("accountsChanged", handleAccountsChanged);
            unisat.removeListener("networkChanged", handleNetworkChanged);
        };
    }


    useEffect(() => {
        checkUnisatInstalled().then();
    }, []);

    // useEffect(() => {
    //   console.log(" unisatWallet =---> ", unisatWallet);
    // }, [unisatWallet]);

    useEffect(() => {
        if (unisatWallet !== unisatInfo) {
            console.log(" setUnisatWallet / unisatInfo = ", unisatInfo);
            setUnisatWallet(unisatInfo);
        }
    }, [unisatInfo]);

    const connectUnisat = async () => {

        if (!unisatInstalled) {

            // window.location.href = "https://unisat.io";     
            window.open('https://unisat.io', '_blank');
        }
        else {

            checkUnisat().then();

            const unisat = (window).unisat;
            const result = await unisat.requestAccounts();
            handleAccountsChanged(result);
        }

        closePop();
    }

    const connectOKX = async () => {

        if (typeof window.okxwallet !== 'undefined') {
            console.log('OKX is installed!');
            const okxwallet = window.okxwallet;
            const result = await okxwallet.bitcoin.connect();

            if (result) {
                try {
                    console.log("result = ", result);

                    const address = result.address;
                    const publicKey = result.publicKey;
                    let balance = await okxwallet.bitcoin.getBalance();
                    const network = await okxwallet.bitcoin.getNetwork();

                    setUnisatInfo(prevState => ({
                        ...prevState,
                        connected: true,
                        address: address,
                        publicKey: publicKey,
                        balance: balance,
                        network: network,
                        walletType: "OKX"
                    }));

                    window.okxwallet.bitcoin.on('accountChanged', (addressInfo) => {
                        console.log("addressInfo = ", addressInfo);
                        // example
                        // {
                        //   "address": "bc1pwqye6x35g2n6xpwalywhpsvsu39k3l6086cvdgqazlw9mz2meansz9knaq",
                        //   "publicKey": "4a627f388196639041ce226c0229560127ef9a5a39d4885123cd82dc82d8b497",
                        //   "compressedPublicKey": "034a627f388196639041ce226c0229560127ef9a5a39d4885123cd82dc82d8b497"
                        // }

                        if (addressInfo) {
                            setUnisatInfo(prevState => ({
                                ...prevState,
                                address: addressInfo.address,
                                walletType: "OKX"
                            }));
                        }
                        else {
                            //disconnect
                            updateUnisatInfo("connected", false);

                            // setUnisatInfo(prevState => ({
                            //     ...prevState,
                            //     connected: false,
                            //     address: "",
                            //     publicKey: "",
                            //     balance: {}, 
                            //     network: "",
                            //     walletType: ""
                            // }));
                        }
                    });

                } catch (e) {
                    console.log(e);
                }
            }

            closePop();
        }
        else {
            triggerNotification("submit", "Please install OKX wallet extension.", "warning");

            const dappUrl = "https://satopad.finance/";
            const encodedDappUrl = encodeURIComponent(dappUrl);
            const deepLink = "okx://wallet/dapp/url?dappUrl=" + encodedDappUrl;
            const encodedUrl = "https://www.okx.com/download?deeplink=" + encodeURIComponent(deepLink);
            console.log(encodedUrl);

            window.open(encodedUrl, '_blank');
        }
    }

    useEffect(() => {

        const runCapabilityCheck = async () => {
            let runs = 0;
            const MAX_RUNS = 20;
            setCapabilityState("loading");

            // the wallet's in-page script may not be loaded yet, so we'll try a few times
            while (runs < MAX_RUNS) {
                try {
                    await getCapabilities({
                        onFinish(response) {
                            setCapabilities(new Set(response));
                            setCapabilityState("loaded");
                        },
                        onCancel() {
                            setCapabilityState("cancelled");
                        },
                        payload: {
                            network: {
                                type: xVerseNetwork,
                            },
                        },
                    });
                } catch (e) {
                    runs++;
                    if (runs === MAX_RUNS) {
                        setCapabilityState("missing");
                    }
                }
                await new Promise((resolve) => setTimeout(resolve, 100));
            }
        };

        runCapabilityCheck();

    }, [xVerseNetwork]);


    const connectXverse = async () => {
        const capabilityMessage =
            capabilityState === "loading"
                ? "Checking capabilities..."
                : capabilityState === "cancelled"
                    ? "Capability check cancelled by wallet. Please refresh the page and try again."
                    : capabilityState === "missing"
                        ? "Could not find an installed Sats Connect capable wallet. Please install a wallet and try again."
                        : !capabilities
                            ? "Something went wrong with getting capabilities"
                            : undefined;

        if (capabilityMessage) {
            triggerNotification("submit", capabilityMessage, "warning");
            closePop();
        }
        else {
            await getAddress({
                payload: {
                    purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment, AddressPurpose.Stacks],
                    message: "SatoPad",
                    network: {
                        type: xVerseNetwork,
                    },
                },
                onFinish: (response) => {

                    const paymentAddressItem = response.addresses.find(
                        (address) => address.purpose === AddressPurpose.Payment
                    );

                    const network = xVerseNetwork === BitcoinNetworkType.Mainnet ? "livenet" : "testnet";

                    setUnisatInfo(prevState => ({
                        ...prevState,
                        connected: true,
                        address: paymentAddressItem?.address,
                        publicKey: paymentAddressItem?.publicKey,
                        // balance: balance,
                        network: network,
                        walletType: "XVERSE"
                    }));

                    closePop();
                },
                onCancel: () => { triggerNotification("submit", 'Request canceled', "warning"); closePop(); }
            });
        }
    }

    const connectLeather = async () => {
        if (window.LeatherProvider) // Leather is installed 🎉 
        {
            const userAddresses = await window.btc?.request('getAddresses');
            console.log(" userAddresses= ", userAddresses);
            const taproot = userAddresses.result.addresses.filter(el => el.symbol === "BTC" && el.type === "p2wpkh");
            console.log("taproot= ", taproot)

            let address = "";
            let publicKey = "";
            if(taproot.length > 0) {
                address = taproot[0].address
                publicKey = taproot[0].publicKey
            }           

            setUnisatInfo(prevState => ({
                ...prevState,
                connected: true,
                address: address,
                publicKey: publicKey,
                // balance: balance,
                network: "livenet",
                walletType: "LEATHER"
            }));

            closePop();
        }
        else {
            triggerNotification("submit", 'Please install Leather wallet.', "warning");
            closePop();
        }
        
    }

    return (
        <div>
            <div
                className={modalPop ? "modal-overlay" : "hide"}
                onClick={closePop}
            ></div>
            <div
                className={
                    modalPop
                        ? "MuiDialog-container fix-modal MuiDialog-scrollPaper yesvisible mui-style-ekeie0"
                        : "MuiDialog-container fix-modal MuiDialog-scrollPaper novisible mui-style-ekeie0"
                }
                role="presentation"
                tabIndex={-1}
                style={{
                    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
            >
                <div
                    className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm mui-style-ixzipm"
                    role="dialog"
                    aria-labelledby=":rv:"
                >
                    <button
                        onClick={closePop}
                        className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium mui-style-8r14ou"
                        tabIndex={0}
                        type="button"
                    >
                        <svg
                            width={48}
                            height={48}
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx={24}
                                cy={24}
                                r="23.5"
                                stroke="white"
                                strokeOpacity="0.2"
                            />
                            <path
                                d="M18 18L30 30"
                                stroke="white"
                                strokeWidth={2}
                                strokeLinecap="square"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18 30L30 18"
                                stroke="white"
                                strokeWidth={2}
                                strokeLinecap="square"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="MuiTouchRipple-root mui-style-w0pj6f" />
                    </button>
                    <div className="MuiDialogContent-root mui-style-1b2gyyf">
                        <div>
                            <div className="MuiStack-root mui-style-1e7g3wv">
                                <p className="MuiTypography-root MuiTypography-body1 mui-style-7war73">
                                    Connect your wallet
                                </p>
                            </div>
                            <div className="MuiStack-root mui-style-kjfzc1">
                                <div
                                    className="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters mui-style-1rkdppy"
                                    tabIndex={0}
                                    role="button"
                                    onClick={connectUnisat}
                                >
                                    <div className="MuiListItemAvatar-root mui-style-a5kqs7">
                                        <div className="MuiBox-root mui-style-144lp64">
                                            <img
                                                alt="Unisat"
                                                loading="lazy"
                                                width={36}
                                                height={36}
                                                decoding="async"
                                                data-nimg={1}
                                                src={UniSatWallet}
                                                style={{ color: "transparent" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="MuiListItemText-root mui-style-1tsvksn">
                                        <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary mui-style-17ha0w2">
                                            {!unisatInstalled ? "Install Unisat Wallet" : "UniSat"}
                                        </span>
                                    </div>
                                    <span className="MuiTouchRipple-root mui-style-w0pj6f" />
                                </div>
                                <div
                                    className="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters mui-style-1rkdppy"
                                    tabIndex={1}
                                    role="button"
                                    onClick={connectOKX}
                                >
                                    <div className="MuiListItemAvatar-root mui-style-a5kqs7">
                                        <div className="MuiBox-root mui-style-144lp64">
                                            <img
                                                alt="OKX"
                                                loading="lazy"
                                                width={36}
                                                height={36}
                                                decoding="async"
                                                data-nimg={1}
                                                src={ico2}
                                                style={{ color: "transparent" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="MuiListItemText-root mui-style-1tsvksn">
                                        <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary mui-style-17ha0w2">
                                            OKX
                                        </span>
                                    </div>
                                    <span className="MuiTouchRipple-root mui-style-w0pj6f" />
                                </div>
                                <div
                                    className="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters mui-style-1rkdppy"
                                    tabIndex={-1}
                                    role="button"
                                    aria-disabled="true"
                                    onClick={connectXverse}
                                >
                                    <div className="MuiListItemAvatar-root mui-style-a5kqs7">
                                        <div className="MuiBox-root mui-style-144lp64">
                                            <img
                                                alt="Xverse"
                                                loading="lazy"
                                                width={36}
                                                height={36}
                                                decoding="async"
                                                data-nimg={1}
                                                src={ico3}
                                                style={{ color: "transparent" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="MuiListItemText-root mui-style-1tsvksn">
                                        <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary mui-style-17ha0w2">
                                            Xverse
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters mui-style-1rkdppy"
                                    tabIndex={-1}
                                    role="button"
                                    aria-disabled="true"
                                    onClick={connectLeather}
                                >
                                    <div className="MuiListItemAvatar-root mui-style-a5kqs7">
                                        <div className="MuiBox-root mui-style-144lp64">
                                            <img
                                                alt="Leather"
                                                loading="lazy"
                                                width={36}
                                                height={36}
                                                decoding="async"
                                                data-nimg={1}
                                                src={LeatherWallet}
                                                style={{ color: "transparent" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="MuiListItemText-root mui-style-1tsvksn">
                                        <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary mui-style-17ha0w2">
                                            Leather
                                        </span>
                                    </div>
                                </div>
                                {/* 
                    <br />
                    <a
                    href="https://unisat.io/"
                    target="_blank"
                    className="create-wallet"
                    >
                    <div
                        className="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters mui-style-1rkdppy"
                        tabIndex={0}
                        role="button"
                    >
                        <div className="MuiListItemAvatar-root mui-style-a5kqs7">
                        <div className="MuiBox-root mui-style-144lp64">
                            <img src={WalletIcon} />
                        </div>
                        </div>
                        <div className="MuiListItemText-root mui-style-1tsvksn">
                        <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary mui-style-17ha0w2">
                            Create a new wallet
                        </span>
                        </div>
                        <span className="MuiTouchRipple-root mui-style-w0pj6f" />
                    </div>
                    </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectWalletDialog;